import React, { useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default function PrivacyPolicy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="App overlayImage">
			<div className="extraPagesBack">
				<Navigation />
				<div className="contentWrapEx">
					<h1>PRIVACY POLICY</h1>
					<div className="textWrap">
						<p>As of December 28, 2022</p>
						<p>
							This Privacy Policy explains Core Memories, LLC (hereinafter
							“Company” or “we”, “our” or “us”) commitments and your rights
							concerning your information. The Privacy Policy is incorporated in
							and subject to the{' '}
							<a href="/terms-and-conditions">Terms And Conditions</a> and{' '}
							<a href="/nftterms">NFT Terms</a> stated on the www.genxnfts.xyz
							website (the “Site”) and governs the Site, its subdomains, and any
							services provided by or in connection with the Site (collectively
							the “Services”). You should read the Privacy Policy carefully. If
							you do not agree with this Privacy Policy, please do not use our
							Site or Services. By using the Site or any of our Services, you
							consent to our collection and use of your information in
							accordance with this Privacy Policy.
						</p>
						<p>
							We ask that you read this Privacy Policy from time to time. We may
							modify it at any time in our sole discretion. If we make material
							changes to this Privacy Policy that increase our rights to use
							personal data that we have previously collected about you, we will
							obtain your consent, where required by law, either through your
							registered email address or by prominent posting on the Site.
							Where permitted by law, your use of the Site and/or Services
							indicates that you accept the terms of this Privacy Policy.
						</p>
						<h3>WE COLLECT INFORMATION THE FOLLOWING WAYS:</h3>
						<p></p>
						<p>
							<strong>
								1. When you give it to us or give us permission to obtain it
							</strong>
							<br />
							We may collect information from you in a variety of ways such as
							your name, email address, social media
							usernames/handles/data/account information (e.g., Twitter,
							Discord, etc.), your billing and/or shipping address, payment card
							information (which is collected and processed by our third-party
							payment processor and we do not have access to your full payment
							card information), phone number and your blockchain-compatible
							digital or cryptocurrency wallet that you connect to our Services
							including, without limitation, the public address thereof
							(“Wallet”). You may, however, visit our Site anonymously if you
							choose to do so. We will collect information from you only if you
							voluntarily choose to submit such information to us. You can
							always refuse to provide this information to us. If you choose to
							subscribe to a Service from us, we or the mobile operator of your
							network will collect your information related to the payment for
							the subscription of our Services. Account and billing information
							collected by the mobile operator is subject to their respective
							privacy policies.
						</p>
						<p>
							When you order or make purchases with respect to certain Services
							through our Site, we may require you to submit your payment
							information via our third-party payment processor. The payment
							information our processor collects may include your credit or
							debit card number or your bank account number, the expiration date
							of your credit or debit card, purchase amount, date of purchase,
							payment method and/or any Wallet information. Our processor may
							also collect certain personal information from you, such as your
							name, billing address, and, if applicable, shipping address. We do
							not have access to full payment card numbers or bank account
							numbers.
						</p>
						<p>
							On occasion, we may provide you with the opportunity to
							participate in contests/sweepstakes, receive a special offer, or
							provide us with feedback via a survey. To make these Services
							available to you, we will use the email address, name, Wallet,
							and/or social media usernames/handles/data/account information
							(e.g., Twitter, Discord, etc.) that you provided us when you
							registered.
						</p>
						<p>
							<strong>
								2. We get technical information when you visit our Site and/or
								Services
							</strong>
							<br />
							We may collect technical information about you whenever you visit
							our Site or use our Services. Technical information we collect may
							include the user agent, and technical information about your means
							of connection to our Site, such as the type of device you use, the
							device screen size, browser type, geographic location (country
							only), unique device identifiers, Internet Protocol address, mouse
							events (movements, location and number of clicks), landing pages,
							operating system, information about your use of our Services and
							other similar information.
						</p>
						<p>
							We also use cookies and other technologies to collect technical
							information from you. A cookie is a piece of information that is
							stored on your device’s hard drive for record-keeping purposes and
							sometimes to track information. For example, we use cookies to
							store your language preferences or other redundant settings, so
							you don’t have to set them up every time you visit our Site or
							Services.{' '}
						</p>
						<p>
							<strong>
								3. Our partners and advertisers collect and share information
								with us
							</strong>
							<br />
							We may use Google Analytics, a web analysis service of Google Inc.
							(“Google”), and other similar services, which assist website
							owners and operators in learning and understanding the usage
							patterns of visitors who use their websites by collecting
							information, namely; which individual web pages were visited, how
							long users spent on the website, which websites referred the most
							visitors, general geographic location of visitors, and other
							similar anonymous statistics.
						</p>
						<p>
							For this reason, Google Analytics and such other services may
							create and store several cookies on your device. Google Analytics
							does not collect any personally identifiable information by
							default. Google Analytics collects anonymized IP addresses or
							internet connections used by visitors to websites on which Google
							Analytics has been installed. Google Analytics does not reveal or
							expose the IP address of visitors to the owners of the website on
							which Google Analytics is installed.
						</p>
						<p>
							<strong>4. Blockchain Information</strong>
							<br />
							As part of the Services, we process certain personal data in a
							blockchain data record. This means that such personal data is
							stored in an immutable blockchain as part of a database called a
							distributed ledger. Anything stored in a ledger as part of the
							blockchain (“Blockchain Information”) may not be changed or
							deleted. Blockchain Information includes, but is not limited to,
							(i) the public address of any Wallet; (ii) any blockchain-based or
							Web3 domain names (e.g., a domain name registered with the
							Ethereum Name Service or Unstoppable Domains) associated with the
							Wallet; and (iii) corresponding data relating to the minting,
							purchase, sale, transfer, and any other transaction involving
							non-fungible tokens (NFTs) or cryptocurrency, including the
							purchase price, date, time, and location of the transactions. We
							may use pseudonymization to reduce the risk to individuals whose
							personal data is processed in the blockchain. Please carefully
							review how the use of blockchain with our Service may limit the
							exercise of certain of your rights regarding personal data in
							Privacy Notice for California Residents included below in this
							Privacy Policy.
						</p>
						<h3>HOW WE USE COLLECTED INFORMATION</h3>
						<p></p>
						<p>We may use your information for the following purposes:</p>
						<ul>
							<li>Provide the Service(s) you request;</li>
							<li>
								Allow you to participate in certain features of the Site,
								including Services;
							</li>
							<li>Bill and collect for the Service(s) provided to you;</li>
							<li>Send you marketing communications;</li>
							<li>Provide customer care;</li>
							<li>
								Provide co-branded services and features (such as contests,
								sweepstakes, or other promotions);
							</li>
							<li>Protect our rights or property;</li>
							<li>
								Enforce the{' '}
								<a href="/terms-and-conditions">Terms And Conditions</a> and/or{' '}
								<a href="/nftterms">NFT Terms</a> stated on the Site;
							</li>
							<li>Comply with legal requirements;</li>
							<li>To improve our Site and Services;</li>
							<li>
								Provide you with additional information regarding products,
								events, promotions, and services from us and third parties that
								may be of interest to you via email, text message, or through
								advertising on various social media platforms or websites (both
								desktop and mobile) that you may visit;
							</li>
							<li>
								Develop, improve, and target our promotional messaging to you
								and others via retargeting, online behavioral advertising, and
								use of social media platform tools for advertising;
							</li>
							<li>
								In connection with, or during negotiations of, any merger,
								acquisition by another company, or sale of all or substantially
								all of our business or assets;
							</li>
							<li>
								Respond to your inquiries, questions, and/or other requests; and
							</li>
							<li>
								Respond to court orders, subpoenas or other legal proceedings.
							</li>
						</ul>
						<p></p>
						<p>
							According to privacy legislation, we are required to disclose on
							what legal grounds we are able to use your information. We require
							your information because of the contractual agreements between you
							and us.
						</p>
						<p>
							In addition, we can use your information in the case of legitimate
							interest. This means that we want to provide you the most optimal
							and personalized service possible. Of course, we keep your privacy
							in mind at all times.
						</p>
						<p>
							In certain cases, we may have a legal obligation to use your
							information. For example, when we suspect fraud. In other
							instances, you may have provided us permission to use your
							information, for example when you sign up for a newsletter or
							other mailing lists. In all cases, we keep track of this
							appropriately.
						</p>
						<p>
							California residents may have additional personal information
							rights and choices. Please see our Privacy Notice for California
							Residents included below in this Privacy Policy for more
							information.
						</p>
						<p>
							The Site is operated in the United States. If you are located in
							another jurisdiction, please be aware that the data we collect
							will be transferred to, stored, and processed in the United
							States. By using the Site and/or providing us with any data, you
							consent to this transfer, processing, and storage of your data in
							the United States, a jurisdiction in which the privacy laws may
							not be as comprehensive as those in the country where you reside
							or are a citizen such as the European Union. We will take all
							steps reasonably necessary to ensure that your data is treated
							securely and in accordance with this Privacy Policy. We will take
							all steps reasonably necessary to ensure that your data is subject
							to appropriate safeguards and ensure that your data is treated
							securely and in accordance with this Privacy Policy.
						</p>
						<h3>HOW LONG WE KEEP YOUR INFORMATION</h3>
						<p>
							We will retain your information for as long as needed to provide
							our Services to you. We will retain your information as necessary
							to comply with our legal obligations, resolve disputes and enforce
							our agreements.
						</p>
						<p>
							When we no longer need to use your information and there is no
							need for us to keep it to comply with our legal or regulatory
							obligations, we will either remove it from our systems or
							depersonalize it so that we can’t identify you.
						</p>
						<h3>HOW WE PROTECT YOUR INFORMATION</h3>
						<p>
							We are concerned with protecting your privacy and data, but we
							cannot ensure or warrant the security of any information you
							transmit to us or guarantee that your information may not be
							accessed, disclosed, altered or destroyed by breach of any of our
							industry standard physical, technical or managerial safeguards. We
							adopt appropriate data collection, storage and processing
							practices and security measures to protect against unauthorized or
							accidental access, alteration, disclosure or destruction of your
							information, transaction information and data stored on our Site.
						</p>
						<p>
							However, no method of transmission over the internet or method of
							electronic storage is 100% secure. Therefore, we cannot guarantee
							its absolute security. We will continue to enhance our security
							procedures as new technology becomes available.
						</p>
						<h3>SHARING YOUR INFORMATION</h3>
						<p>
							We share your personal information with third party companies who
							provide services on our behalf and support our operations. These
							services may include product and/or experience customization,
							distribution of marketing and promotional material, Site and
							Services evaluation, data collection, storage, management,
							analysis, and deletion, sponsors, promotional partners, or event
							partners in connection with certain contests, sweepstakes,
							promotions, or ticketed events, third-party vendors and partners
							that sell items, offer promotions, or provide services to you
							through our services (either alone or jointly with us), and other
							support services as necessary, including ad delivery and analysis.{' '}
						</p>
						<p>
							We may also share your personal information when we determine that
							doing so is appropriate to our and your security. This may include
							verifying compliance with and to enforce our{' '}
							<a href="/terms-and-conditions">Terms And Conditions</a> and/or{' '}
							<a href="/nftterms">NFT Terms</a> stated on the Site, to protect
							our property, the Services, the Site and our legal rights, to
							protect the personal safety or the property of others, to respond
							to lawful requests from governmental authorities, to comply with
							applicable laws, and to support auditing, compliance and corporate
							governance functions.
						</p>
						<p>
							Through your use of the Site, you may access, directly or
							indirectly, other websites or services, including from our service
							providers, business partners, and other third parties, in each
							case, that are not owned or controlled by us. Data collection and
							use by those third parties is subject to the privacy policies of
							those third parties. It is up to you to review the terms of use
							and privacy policies of those third parties.
						</p>
						<h3>CHILDREN’S PRIVACY</h3>
						<p>
							Protecting the privacy of young children is especially important.
							For that reason, we do not knowingly collect or solicit
							information from anyone under the age of 13. In the event that we
							learn that we have collected information from a child under age
							13, we will delete that information as quickly as possible. If you
							believe that we might have any information from or about a child
							under 13, please contact us by sending an e-mail to
							signupgenxnfts@gmail.com.
						</p>
						<p>
							California residents under 16 years of age may have additional
							rights regarding the collection and sale of their personal
							information. Please see our Privacy Notice for California
							Residents included below in this Privacy Policy for more
							information.
						</p>
						<p>
							CALIFORNIA PRIVACY RIGHTS If you are a California resident,
							California law may provide you with additional rights regarding
							our use of your personal information. To learn more about your
							California privacy rights, please see our Privacy Notice for
							California Residents included below in this Privacy Policy.
						</p>
						<h3>EU PRIVACY POLICY</h3>
						<p>
							If you are a resident of the European Union, please review our
							Privacy Notice for EU section below for additional information
							about your rights.
						</p>
						<h3>CONTACTING US</h3>
						<p>
							We want you to be in control of how your personal data is used by
							us. You can do this in the following ways:
						</p>
						<ol>
							<li>
								You can ask us for a copy of the personal data we hold about
								you;
							</li>
							<li>
								You can inform us of any changes to your personal data, or you
								can ask us to correct any of the personal data we hold about
								you;
							</li>
							<li>
								In certain situations, you can ask us to erase, block, or
								restrict the processing of the personal data we hold about you,
								or object to particular ways in which we are using your personal
								data; and
							</li>
							<li>
								In certain situations, you can also ask us to send the personal
								data you have given us to a third party. Where we use your
								personal data on the basis of your consent, you are entitled to
								withdraw that consent at any time subject to applicable law.
								Moreover, where we process your personal data based on
								legitimate interest, you have the right to object at any time to
								that use of your personal data subject to applicable law.
							</li>
						</ol>
						<p></p>
						<p>
							We rely on you to ensure that your personal data is complete,
							accurate and current. Please inform us of any changes to or
							inaccuracies of to your personal data by contacting us
							immediately.
						</p>
						<p>
							For any of your requests, as well as questions about this Privacy
							Policy, the practices of the Site, or your dealings with the Site,
							please contact us by sending an e-mail to
							signupgenxnfts@gmail.com.{' '}
						</p>
						<p>
							You can contact the same e-mail address if you want to object to
							the processing of your personal data on the basis of legitimate
							interest and no opt-out mechanism is available to you directly.
						</p>
						<hr />
						<p>As of December 28, 2022</p>
						<p>
							This Privacy Notice for California Residents supplements the
							information contained in our Privacy Policy and applies solely to
							all visitors, users, and others who reside in the State of
							California (“consumers” or “you”). This Privacy Notice for
							California Residents is incorporated in and subject to the{' '}
							<a href="/terms-and-conditions">Terms And Conditions</a> and{' '}
							<a href="/nftterms">NFT Terms</a> stated on the Site and governs
							the same Services and Site(s) as the ones mentioned in the Terms
							And Conditions stated on the Site. Any terms we use in this
							Privacy Notice for California Residents without defining them have
							the same definition given to them in the Privacy Policy or Terms
							And Conditions stated on the Site, as applicable.
						</p>
						<p>
							We adopt this notice to comply with the California Consumer
							Privacy Act of 2018 (“CCPA”) and any terms defined in the CCPA
							have the same meaning when used in this notice.
						</p>
						<p>
							We ask that you read this Privacy Notice for California Residents
							from time to time. We may modify it at any time in our sole
							discretion. If we make material changes to this Privacy Notice for
							California Residents that increase our rights to use personal data
							that we have previously collected about you, we will obtain your
							consent, where required by law, either through your registered
							email address or by prominent posting on the Site. Where permitted
							by law, your use of the Site and/or Services indicates that you
							accept the terms of this Privacy Notice for California Residents.
						</p>
						<h3>INFORMATION WE COLLECT</h3>
						<p>
							We collect information that identifies, relates to, describes, is
							reasonably capable of being associated with, or could reasonably
							be linked, directly or indirectly, with a particular consumer or
							household (“personal information”).
						</p>
						<p>
							Personal information does not include (i) publicly available
							information from government records, (ii) anonymized or aggregated
							consumer information, or (iii) information excluded from the
							CCPA’s scope, such as health or medical information covered by the
							Health Insurance Portability and Accountability Act of 1996 and
							the California Confidentiality of Medical Information Act or
							information covered by certain sector-specific privacy laws
							including the Fair Credit Reporting Act, the Gramm-Leach-Bliley
							Act, the California Financial Information Privacy Act, and the
							Driver’s Privacy Protection Act of 1994.
						</p>
						<table>
							<tr>
								<th>Categories</th>
								<th>Examples</th>
								<th>Collected</th>
							</tr>
							<tr>
								<td>A. Identifiers</td>
								<td>
									A real name, alias, unique personal identifier, online
									identifier, internet protocol address, email address, account
									name, or other similar identifiers.
								</td>
								<td>Yes</td>
							</tr>
							<tr>
								<td>
									B. Personal information categories listed in the California
									Customer Records statue (Cal. Civ. Code § 1798.80(e))
								</td>
								<td>
									A name, signature, social security number, physical
									characteristics or description, address, telephone number,
									passport number, driver’s license or state identification card
									number, insurance policy number, education, employment,
									employment history, bank account number, credit card number,
									debit card number, or any other financial information, medical
									information, or health insurance information.
									<br />
									Some personal information included in this category may
									overlap with other categories
								</td>
								<td>No</td>
							</tr>
							<tr>
								<td>
									C. Protected classification characteristics under California
									or Federal law
								</td>
								<td>
									Age (40 years or older), race, color, ancestry, national
									origin, citizenship, religion or creed, marital status,
									medical condition, physical or mental disability, sex
									(including gender, gender identity, gender expression,
									pregnancy or childbirth and related medical conditions),
									sexual orientation, veteran or military status, genetic
									information (including familial genetic information).
								</td>
								<td>No</td>
							</tr>
							<tr>
								<td>D. Commercial information</td>
								<td>
									Records of personal property, products or services purchased,
									obtained, or considered, or other purchasing or consuming
									histories or tendencies.
								</td>
								<td>Yes</td>
							</tr>
							<tr>
								<td>E. Biometric Information</td>
								<td>
									Genetic, physiological, behavioral, and biological
									characteristics, or activity patterns used to extract a
									template or other identifier or identifying information, such
									as, fingerprints, faceprints, and voiceprints, iris or retina
									scans, keystroke, gait, or other physical patterns, and sleep,
									health, or exercise data.
								</td>
								<td>No</td>
							</tr>
							<tr>
								<td>F. Internet or other similar network activity.</td>
								<td>
									Browsing history, search history, information on a consumer’s
									interaction with a website, application, or advertisement.
								</td>
								<td>Yes</td>
							</tr>
							<tr>
								<td>G. Geolocation data.</td>
								<td>Physical location or movements.</td>
								<td>No</td>
							</tr>
							<tr>
								<td>H. Sensory data.</td>
								<td>
									Audio, electronic, visual, thermal, olfactory, or similar
									information.
								</td>
								<td>No</td>
							</tr>
							<tr>
								<td>I. Professional or employment related information</td>
								<td>Current or past job history or performance evaluations.</td>
								<td>No</td>
							</tr>
							<tr>
								<td>
									J. Non-public education information (per the Family
									Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
									34 C.F.R. Part 99))
								</td>
								<td>
									Education records directly related to a student maintained by
									an educational institution or party acting on its behalf, such
									as grades, transcripts, class lists, student schedules,
									student identification codes, student financial information,
									or student disciplinary records.
								</td>
								<td>No</td>
							</tr>
							<tr>
								<td>K. Inferences draw from other personal information.</td>
								<td>
									Profile reflecting a person’s preferences, characteristics,
									psychological trends, predispositions, behavior, attitudes,
									intelligence, abilities, and aptitudes.
								</td>
								<td>No</td>
							</tr>
						</table>
						<p>
							As further detailed in our Privacy Policy, we obtain the
							categories of personal information listed above from the following
							categories of sources:
						</p>
						<ul>
							<li>
								<u>Directly from you</u>. For example, information that you
								provide by filling in forms, records and copies of your
								correspondence if you contact us, details of transactions and
								your subscriptions to the Services.
							</li>
							<li>
								<u>Indirectly from you</u>. For example, observing your actions
								on the Sites or your use of our Services, or through the use of
								cookies or other technologies to automatically collect
								information from you.
							</li>
							<li>
								<u>Third party business partners</u>. For example, any payment
								processing service, NFT app/platform or otherwise.
							</li>
						</ul>
						<h3>USE OF PERSONAL INFORMATION</h3>
						<p>
							As further detailed in our Privacy Policy, we may use or disclose
							the personal information we collect for one or more of the
							following business purposes:
						</p>
						<ul>
							<li>Provide the Service(s) you request;</li>
							<li>Bill and collect for the Service(s) provided to you;</li>
							<li>Send you marketing communications;</li>
							<li>Provide customer care;</li>
							<li>Protect our rights or property;</li>
							<li>
								Enforce the{' '}
								<a href="/terms-and-conditions">Terms And Conditions</a> and{' '}
								<a href="/nftterms">NFT Terms</a> stated on the Site;
							</li>
							<li>Comply with legal requirements;</li>
							<li>Improve our Sites and Services;</li>
							<li>
								Respond to your inquiries, questions and/or other requests;
							</li>
							<li>
								Respond to court orders, subpoenas or other legal proceedings;
								and
							</li>
							<li>
								As described to you when collecting your personal information or
								as otherwise set forth in the CCPA.
							</li>
						</ul>
						<p>
							We will not collect additional categories of personal information
							or use the personal information we collected for materially
							different, unrelated, or incompatible purposes without providing
							you notice.
						</p>
						<h3>SHARING PERSONAL INFORMATION</h3>
						<p>
							We may share personal information to a third party for a business
							purpose. When we disclose personal information for a business
							purpose, we enter a contract that describes the purpose and
							requires the recipient to both keep the personal information
							confidential and not use it for any purpose except performing the
							contract.
						</p>
						<p>
							We share personal information with the following categories of
							third parties:
						</p>
						<ul>
							<li>Service providers;</li>
							<li>Advertisers;</li>
							<li>
								Internet cookie data recipients (for example, Google Analytics);
								and
							</li>
							<li>Parent or subsidiary organizations.</li>
						</ul>
						<h3>DISCLOSURES OF PERSONAL INFORMATION FOR A BUSINESS PURPOSE</h3>
						<p>
							In the preceding twelve (12) months, we have disclosed and/or may
							disclose the following categories of personal information:
						</p>
						<ul>
							<li>Identifiers;</li>
							<li>
								California customer records personal information categories;
							</li>
							<li>Commercial information; and</li>
							<li>Internet or other similar network activity.</li>
						</ul>
						<h3>SALES OF PERSONAL INFORMATION</h3>
						<p>We do not sell personal information for any purpose.</p>
						<h3>YOUR RIGHTS AND CHOICES</h3>
						<p>
							The CCPA provides consumers (California residents) with specific
							rights regarding their personal information. This section
							describes your CCPA rights and explains how to exercise those
							rights.
						</p>
						<h3>ACCESS TO SPECIFIC INFORMATION AND DATA PORTABILITY RIGHTS</h3>
						<p>
							You have the right to request that we disclose certain information
							to you about our collection and use of your personal information
							over the past 12 months. Once we receive and confirm your
							verifiable consumer request (see the Exercising Access, Data
							Portability, and Deletion Rights section below), we will disclose
							to you:
						</p>
						<ul>
							<li>
								The categories of personal information we collected about you;
							</li>
							<li>
								The categories of sources for the personal information we
								collected about you;
							</li>
							<li>
								Our business or commercial purpose for collecting or selling
								that personal information;
							</li>
							<li>
								The categories of third parties with whom we share that personal
								information;
							</li>
							<li>
								The specific pieces of personal information we collected about
								you (also called a data portability request); and
							</li>
							<li>
								If we disclosed your personal information for a business
								purpose, identifying the personal information categories that
								each category of recipient obtained.
							</li>
						</ul>
						<h3>DELETION REQUEST RIGHTS</h3>
						<p>
							You have the right to request that we delete any of your personal
							information that we collected from you and retained, subject to
							certain exceptions. Once we receive and confirm your verifiable
							consumer request (see the Exercising Access, Data Portability, and
							Deletion Rights section below), we will delete (and direct our
							service providers to delete) your personal information from our
							records, unless an exception applies.
						</p>
						<p>
							We may deny your deletion request if retaining the information for
							us or our service provider(s) to:
						</p>
						<ul>
							<li>
								Complete the transaction for which we collected the personal
								information, provide a good or service that you requested, take
								actions reasonably anticipated within the context of our ongoing
								business relationship with you, or otherwise perform our
								contract with you.
							</li>
							<li>
								Detect security incidents, protect against malicious, deceptive,
								fraudulent, or illegal activity, or prosecute those responsible
								for such activities.
							</li>
							<li>
								Debug products to identify and repair errors that impair
								existing intended functionality.
							</li>
							<li>
								Exercise free speech ensure the right of another consumer to
								exercise their free speech rights, or exercise another right
								provided for by law.
							</li>
							<li>
								Comply with the California Electronic Communications Privacy Act
								(Cal. Penal Code § 1546 et. seq.).
							</li>
							<li>
								Engage in public or peer-reviewed scientific, historical, or
								statistical research in the public interest that adheres to all
								other applicable ethics and privacy laws, when the information’s
								deletion may likely render impossible or seriously impair the
								research’s achievement, if you previously provided informed
								consent.
							</li>
							<li>
								Enable solely internal uses that are reasonably aligned with
								consumer expectations based on your relationship with us.
							</li>
							<li>Comply with a legal obligation.</li>
							<li>
								Make other internal and lawful uses of that information that are
								compatible with the context in which you provided it.
							</li>
						</ul>
						<h3>EXERCISING ACCESS, DATA PORTABILITY, AND DELETION RIGHTS</h3>
						<p>
							To exercise the access, data portability, and deletion rights
							described above please submit a verifiable consumer request to us
							via:
						</p>
						<ul>
							<li>Email: signupgenxnfts@gmail.com </li>
						</ul>
						<p></p>
						<p>
							Only you, or a person registered with the California Secretary of
							State that you authorize to act on your behalf, may make a
							verifiable consumer request related to your personal information.
							You may also make a verifiable consumer request on behalf of your
							minor child.
						</p>
						<p>
							You may only make a verifiable consumer request for access or data
							portability twice within a twelve (12) month period. The
							verifiable consumer request must:
						</p>
						<ul>
							<li>
								Provide sufficient information that allows us to reasonably
								verify you are the person about whom we collected personal
								information or an authorized representative; and
							</li>
							<li>
								Describe your request with sufficient detail that allows us to
								properly understand, evaluate, and respond to it.
							</li>
						</ul>
						<p>
							We cannot respond to your request or provide you with personal
							information if we cannot verify your identity or authority to make
							the request and confirm the personal information relates to you.
						</p>
						<p>
							Making a verifiable consumer request does not require you to
							create an account with us. We will only use personal information
							provided in a verifiable consumer request to verify the
							requestor’s identity or authority to make the request.
						</p>
						<h3>RESPONSE TIMING AND FORMAT</h3>
						<p>
							We endeavor to respond to a verifiable consumer request within
							forty-five (45) days of its receipt. If we require more time, we
							will inform you of the reason and extension period in writing. We
							will send you confirmation of receipt of any verifiable consumer
							request within ten (10) days of its receipt.
						</p>
						<p>
							We will deliver our written response by mail or electronically at
							your option.
						</p>
						<p>
							Any disclosures we provide will only cover the twelve (12) month
							period preceding the verifiable consumer request’s receipt. The
							response we provide will also explain the reasons we cannot comply
							with a request, if applicable. For data portability requests, we
							will select a format to provide your personal information that is
							readily usable and should allow you to transmit the information
							from one entity to another without hindrance.
						</p>
						<p>
							We do not charge a fee to process or respond to your verifiable
							consumer request unless it is excessive, repetitive, or manifestly
							unfounded. If we determine that the request warrants a fee, we
							will tell you why we made that decision and provide you with a
							cost estimate before completing your request.
						</p>
						<h3>NON-DISCRIMINATION</h3>
						<p>
							We will not discriminate against you for exercising any of your
							CCPA rights. Unless permitted by the CCPA, we will not:
						</p>
						<ul>
							<li>Deny you goods or services;</li>
							<li>
								Charge you different prices or rates for goods or services,
								including through granting discounts or other benefits, or
								imposing penalties;
							</li>
							<li>
								Provide you a different level or quality of goods or services;
								or
							</li>
							<li>
								Suggest that you may receive a different price or rate for goods
								or services or a different level or quality of goods or
								services.
							</li>
						</ul>
						<h3>CONTACT INFORMATION</h3>
						<p>
							If you have any questions, concerns, or comments about this
							notice, the way in which we collect and use your information
							described above and in the Privacy Policy, your choices and rights
							regarding such use, please do not hesitate to contact us at:
						</p>
						<ul>
							<li>Email: signupgenxnfts@gmail.com </li>
						</ul>
						<hr />
						<p>As of December 28, 2022</p>
						<p>
							This Privacy Notice for EU supplements the information contained
							in our Privacy Policy and applies solely to all visitors, users,
							and others who reside in the European Union, or “EU” (“consumers”
							or “you”), relating to your rights under the General Data
							Protection Regulation (“GDPR”). This Privacy Notice for EU is
							incorporated in and subject to the Terms And Conditions and NFT
							Terms stated on the Site and governs the same Services and Site(s)
							as the ones mentioned in the{' '}
							<a href="/terms-and-conditions">Terms And Conditions</a> and NFT
							Terms stated on the Site. Any terms we use in this Privacy Notice
							for EU Residents without defining them have the same definition
							given to them in the Privacy Policy, GDPR, or{' '}
							<a href="/terms-and-conditions">Terms And Conditions</a> or{' '}
							<a href="/nftterms">NFT Terms</a> stated on the Site, as
							applicable.
						</p>
						<p>
							We ask that you read this Privacy Notice for EU from time to time.
							We may modify it at any time in our sole discretion. If we make
							material changes to this Privacy Notice for EU that increase our
							rights to use personal data that we have previously collected
							about you, we will obtain your consent, where required by law,
							either through your registered email address or by prominent
							posting on the Site. Where permitted by law, your use of the Site
							and/or Services indicates that you accept the terms of this
							Privacy Notice for EU.
						</p>
						<h3>YOUR GDPR RIGHTS</h3>
						<p>
							The GDPR provides residents of the EU with the following rights
							regarding their personal data:
						</p>
						<ul>
							<li>
								Right to be informed about the collection and use of your
								personal data
							</li>
							<li>Right to access your personal data</li>
							<li>Right to have any inaccurate personal data corrected</li>
							<li>Right to have your personal data erased</li>
							<li>Right to restrict use of your personal data</li>
							<li>Right to personal data portability</li>
							<li>Right to object to the processing of your personal data</li>
							<li>Right to object to automated decision making </li>
						</ul>
						<p>
							To exercise any of these rights, please submit a verifiable
							consumer request to us via:
						</p>
						<ul>
							<li>Email: signupgenxnfts@gmail.com. </li>
						</ul>
						<p></p>
						<p>
							We will respond to any requests within the time required by the
							GDPR. After you submit your request, you will be sent a
							confirmation email and need to click on the link provided to
							confirm your request. Please note that we may need additional
							information from you to verify your identity before we process
							your request.
						</p>
						<h3>LEGAL BASIS FOR PROCESSING PERSONAL DATA</h3>
						<p>
							To the extent you provide us with personal data, we are processing
							your personal data under one of the following lawful bases:
						</p>
						<ul>
							<li>
								Consent. By opting-in, you consent to permit us to process your
								personal data for the purposes set forth in our Privacy Policy
								above.
							</li>
							<li>
								Compliance with Legal Obligations. We may process your personal
								data in order to comply with certain of our legal obligations.
							</li>
						</ul>
						<h3>
							COOKIES, PIXEL TAGS, AND OTHER SIMILAR TRACKING TECHNOLOGIES
						</h3>
						<p>
							To the extent required by applicable law, we will obtain your
							consent before collecting data using cookies, pixel tags, and
							other similar tracking technologies on the Site. If you have
							accepted our use of cookies, pixel tags, and other tracking
							technologies, we will collect your data in accordance with our
							Privacy Policy above based on your affirmative informed consent,
							which you may withdraw through the methods provided herein. If you
							have not accepted, then we only collect your personal data based
							on our legitimate interests.{' '}
						</p>
						<ul>
							<li>
								Consent. By opting-in, you consent to permit us to process your
								personal data for the purposes set forth in our Privacy Policy
								above.
							</li>
							<li>
								Compliance with Legal Obligations. We may process your personal
								data in order to comply with certain of our legal obligations.
							</li>
						</ul>
						<h3>CONTACT INFORMATION</h3>
						<p>
							If you have any questions, concerns, or comments about this
							notice, the way in which we collect and use your information
							described above and in the Privacy Policy, your choices and rights
							regarding such use, or to exercise your rights, please do not
							hesitate to contact us at:
						</p>
						<ul>
							<li>Email: signupgenxnfts@gmail.com.</li>
						</ul>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
